import React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';

import '../styles/Contact.css';

const Contact = props => {
  return (
    <div className='contact-page-color-wrapper'>
      <div className='contact-wrapper'>
        <div className='contact-header'>
          <h2>Let's chat</h2>
          <p>Fill out the form below to drop me an email. </p>
        </div>
        <Form action='https://formspree.io/bfeole@gmail.com' method='POST'>
          <FormGroup>
            <Label for='yourName'>Your Name</Label>
            <Input
              type='text'
              name='name'
              id='yourName'
              placeholder='Enter Your Name Here'></Input>
          </FormGroup>
          <FormGroup>
            <Label for='yourEmail'>Your Email Address</Label>
            <Input
              type='email'
              name='email'
              id='yourEmail'
              placeholder='Enter Email Address Here'
            />
          </FormGroup>
          <FormGroup>
            <Label for='yourText'>Your Message</Label>
            <Input
              type='textarea'
              name='text'
              id='yourText'
              placeholder='Enter Your Message Here'
            />
          </FormGroup>
          <Button>Submit</Button>
        </Form>
      </div>
    </div>
  );
};

export default Contact;

import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from './components/Home';
import Contact from './components/Contact';
import Projects from './components/Projects';
import Resume from './components/Resume';

import './App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

const history = createBrowserHistory();

export default class App extends Component {
  render() {
    return (
      <div className='App'>
        <Router history={history}>
          <NavBar />
          <Route exact path='/' component={Home} />
          {/* <Route exact path='/aboutme' component={Aboutme} /> */}
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/projects' component={Projects} />
          <Route exact path='/resume' component={Resume} />
          <Footer />
        </Router>
      </div>
    );
  }
}

import React, { Component } from 'react';

class Resume extends Component {
  render() {
    return (
      <div>
        <h1>Resume</h1>
      </div>
    );
  }
}

export default Resume;

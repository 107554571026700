import React, { Component } from 'react';

// Styles
import '../styles/Home.css';
import Jumbo from './Jumbo';

import Homecards from './Homecards';

export class Home extends Component {
  render() {
    return (
      <div className='home-wrapper'>
        <Jumbo />
        <Homecards />
      </div>
    );
  }
}

export default Home;

import React from 'react';

// Styles
import '../styles/Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';

const Jumbo = () => {
  return (
    <div>
      <div className='bg-opacity'></div>
      <div className='banner-content'>
        <img
          src={require('../assets/me_ava.png')}
          alt='head'
          className='head-portrait'
        />
        <div className='home-text'>
          <h1>Ben Feole</h1>
          <h2>Full Stack Web Developer</h2>
        </div>
        <div className='social-links'>
          <a href='https://github.com/bfeole'>
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a href='/contact'>
            <FontAwesomeIcon icon={faAngleDoubleDown} />
          </a>
          <a href='https://www.linkedin.com/in/benfeole/'>
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
        <div className='home-skills'>
          <h4>
            React.js || Javascript || Java || Spring || Node.js || Express ||
            PostgreSQL
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Jumbo;

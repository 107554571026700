import React from 'react';

// Styles
import '../styles/Home.css';

// Images
// import toTheMoon from '../assets/to_the_moon.svg';
// import scrum from '../assets/scrum.svg';
// import monsters from '../assets/monsters.svg';

// reactstrap
import { Button } from 'reactstrap';

const Homecards = () => {
  return (
    // <div className='home-content-wrapper'>
    <div className='home-introduction'>
      <div className='home-content'>
        <h3> Hi, I'm Ben.</h3>
        <p>Building websites is what I do.</p>
        <p>
          {' '}
          With a background in digital marketing focusing on e-commerce I've
          experienced first hand the power a website can have on your bottom
          line.
        </p>
        <p>
          I'm currently looking for work. If you'd like to connect tap below to
          get in touch.
        </p>
      </div>
      <div className='home-contact-button'>
        <Button outline color='primary' href='/contact'>
          Contact Me
        </Button>
      </div>
    </div>
    // </div>
  );
};

export default Homecards;

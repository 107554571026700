import React, { useState } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap';

// image
// import elchome from '../assets/elc-home-web.png';
import elchome from '../assets/elchome2.PNG';
import classnames from 'classnames';

import '../styles/Projects.css';

const Projects = props => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className='projects-wrapper'>
      <div className='tabs-wrapper'>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}>
              Exceptional Life Coaching
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2');
              }}>
              More!
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId='1'>
            <Row>
              <Col sm='12'>
                <div className='project-card-wrapper'>
                  <div className='project-image-wrapper'>
                    <img className='project-image' alt='' src={elchome} />
                    <hr />
                    <Row>
                      <Col sm='6'>
                        <div className='project-description'>
                          <h4>About</h4>
                          <p>
                            This site was built using react.js and styled with
                            bootstrap over the course of a week.
                          </p>
                          <h4>Purpose</h4>
                          <p>
                            The vision of this project was to provide a simple
                            and straight forward introduction to a new life
                            coaching company. The expected audience for the site
                            was teens and young adults with intellectual
                            disabilites or special needs and their guardians.{' '}
                          </p>
                        </div>
                      </Col>
                      <Col sm='6'>
                        <div>
                          <h4>Links</h4>
                          {/* <p>Github</p> */}
                          <a href='https://www.elclifeskills.com'>
                            elclifeskills.com
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId='2'>
            <Row>
              <Col sm='12'>
                <div className='project-card-wrapper'>
                  <p>Currently updating my list!</p>
                  <p>
                    Check back soon or visit my{' '}
                    <a href='www.github.com/bfeole'>github</a>.
                  </p>
                </div>
              </Col>
            </Row>
          </TabPane>
          {/* <TabPane tabId='2'>
            <Row>
              <Col sm='12'>
                <div className='project-card-wrapper'>
                  <div className='project-image-wrapper'>
                    <img className='project-image' alt='' src={elchome} />
                    <hr />
                    <Row>
                      <Col sm='6'>
                        <div className='project-description'>
                          <h4>About</h4>
                          <p>
                            This site was built using react.js, redux and
                            materialui on the front end. Node.js, express and
                            postgreSQL hosted on heroku for the backend. The
                            timeline of the project was 6 weeks and consisted of
                            6 contributing developers.
                          </p>
                          <h4>Purpose</h4>
                          <p>
                            {' '}
                            The vision for this app was to create a music
                            recommendation system that provided song
                            recommendations based on your preferences in real
                            time.
                          </p>
                        </div>
                      </Col>
                      <Col sm='6'>
                        <div>
                          <h4>Links</h4>
                          <p>Github</p>
                          <p>elclifeskills.com</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </TabPane> */}
        </TabContent>
      </div>
    </div>
  );
};

export default Projects;

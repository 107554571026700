import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// styling
import './index.css';

// remove mdl
// import 'react-mdl/extra/material.css';
// import 'react-mdl/extra/material.js';

// reactstrap import
import 'bootstrap/dist/css/bootstrap.min.css';

// fontawesome icons
import 'font-awesome/css/font-awesome.min.css';

import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root'),
);

import React, { Component } from 'react';

import '../styles/Footer.css';

export default class Footer extends Component {
  render() {
    return (
      <div className='footer-wrapper'>
        <p className='footer-message'>&copy;Ben Feole 2020</p>
      </div>
    );
  }
}
